<template>
    <v-dialog
            persistent
            v-model="editDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="500px"
            min-width="500px"
            max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text justify-space-between">
                <span>{{ updateMode == false ? $t('message.addSalesColleague') : $t('message.updateSalesColleague') + ": " + salesColleagueName }}</span>
                <HelpPopUpV2 help-page-component="AddSalesColleague" :light="true" :no-padding="true"/>
            </v-card-title>
            <v-card-text>
                <v-overlay
                        :value="loading.get"
                        absolute
                        opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="salesColleagueForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.firstName') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.required, ...validationRules.plainText]"
                                    :value="Salesperson__title"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Salesperson__title = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.lastName') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainText]"
                                    :value="Salesperson__lastname"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Salesperson__lastname = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.appicUser') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items="allUsers"
                                    :rules="[...validationRules.required]"
                                    :value="Salesperson__user_id"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="User.email"
                                    item-value="User.id"
                                    return-object
                                    solo
                                    @change="updateRelatedUser($event)"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.countryManager') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-checkbox
                                    :label="$t('message.yes')"
                                    :true-value="1"
                                    class="mt-0"
                                    dense
                                    hide-details="auto"
                                    v-model="Salesperson__cm"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.salesTeam') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items="filteredSalesColleagues"
                                    :value="Salesperson__cm_salescontact_ids"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    chips
                                    deletable-chips
                                    dense
                                    hide-details="auto"
                                    item-text="Salesperson.name"
                                    item-value="Salesperson.id"
                                    multiple
                                    solo
                                    @change="Salesperson__cm_salescontact_ids = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.phone') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.phoneNumber]"
                                    :value="Salesperson__phone"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Salesperson__phone = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.skype') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :rules="[...validationRules.plainTextWithNumbers]"
                                    :value="Salesperson__skype"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Salesperson__skype = $event"
                            />
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pl-7">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addSalesColleague()"
                        v-if="updateMode == false"
                >{{ $t('message.add') }}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveSalesColleague()"
                        v-if="updateMode == true"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {isObject} from "Helpers/helpers";
const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");

export default {
    name: "AddSalesColleague",
    components: {HelpPopUpV2},
    props: ['salesColleagueId','salesColleagueName','dialog','updateMode','dialog-closed','update-done','create-done'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            edit_dialog: false,
            loading: {
                add: false,
                get: false,
                save: false
            },
            rules: {
                firstName: v => !!v || this.$t('message.required'),
                relatedUser: v => !!v || this.$t('message.required')
            },
            validForm: false
        }
    },
    computed: {
        statePrefix() {
            return this.updateMode ? 'update' : 'current'
        },
        ...mapFieldsPrefixed('salescolleague',{
            Salesperson__lastname: 'Salesperson.lastname',
            Salesperson__cm: 'Salesperson.cm',
            Salesperson__cm_salescontact_ids: 'Salesperson.cm_salescontact_ids',
            Salesperson__phone: 'Salesperson.phone',
            Salesperson__skype: 'Salesperson.skype',
            Salesperson__title: 'Salesperson.title',
            Salesperson__user_id: 'Salesperson.user_id',
            Salesperson__userid: 'Salesperson.userid'
        },'statePrefix'),
        ...mapGetters('salescolleague',{
            allSalesColleagues: 'allSalesColleagues'
        }),
        ...mapGetters('user',{
            allUsers: 'allUsers'
        }),
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
        filteredSalesColleagues() {
            return this.allSalesColleagues.filter(sc => {
                return sc.Salesperson.id != this.salesColleagueId
            })
        }
    },
    methods: {
        ...mapActions('salescolleague',{
            createSalesColleague: 'createSalesColleague',
            getSalesColleagueToUpdateById: 'getSalesColleagueToUpdateById',
            resetSalesColleagueToUpdate: 'resetSalesColleagueToUpdate',
            resetCurrentState: 'resetCurrentState',
            updateSalesColleague: 'updateSalesColleague'
        }),
        ...mapActions('user',{
            getAllUsers: 'getAllUsers'
        }),
        addSalesColleague () {
            if(this.$refs.salesColleagueForm.validate()) {
                this.loading.add = true
                this.createSalesColleague()
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.salesColleagueAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.salesColleagueNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.salesColleagueNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.salesColleagueNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.salesColleagueNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        saveSalesColleague () {
            if(this.$refs.salesColleagueForm.validate()) {
                this.loading.save = true
                this.updateSalesColleague()
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.salesColleagueUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.salesColleagueNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.salesColleagueNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.salesColleagueNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.salesColleagueNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        updateRelatedUser(user) {
            if(user == null){
                this.Salesperson__user_id = null
                this.Salesperson__userid = null
            } else {
                this.Salesperson__user_id = user.User.id
                this.Salesperson__userid = user.User.email
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){
                if(this.updateMode == true) {
                    this.loading.get = true
                    this.getSalesColleagueToUpdateById(this.salesColleagueId)
                        .then( () => {
                            this.loading.get = false
                        })
                } else {
                    this.resetCurrentState()
                }
            } else {
                if(this.updateMode == true) {
                    this.resetSalesColleagueToUpdate()
                } else {
                    this.resetCurrentState()
                }
            }
            this.edit_dialog = value
        }
    },
    mounted() {
        if(this.allUsers.length == 0) this.getAllUsers()
    }
}
</script>

<style>

</style>